import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { Heading, Text } from '@chakra-ui/react';
import { shortcodes as postShortCodes } from './PostMDXRenderer';

const shortcodes = {
  ...postShortCodes
};

export const MDXLayout = ({ children }) => (
  <MDXProvider components={shortcodes}>{children}</MDXProvider>
)
