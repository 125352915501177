import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box, Link, Center, Container, Flex, Heading, HStack, IconButton, Text, TabList, TabPanel, TabPanels, Tabs, useTheme, useBreakpointValue, useColorModeValue, HeadingProps } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CustomTab } from 'src/components/CustomTab';
import NumHeading from 'src/components/NumHeading.tsx';
import { MDXLayout as Layout } from 'src/components/MDXLayout';
import IconListItem from 'src/components/IconListItem';
import IntroCard from 'src/components/IntroCard';
import Coder from './coder.mdx';
import Otaku from './otaku.mdx';
export const query = graphql`
  query {
    aboutPoster: file(relativePath: { eq: "about-poster.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, breakpoints: [750, 1080, 1366, 1920, 2560])
      }
    }
    coderImg: file(relativePath: { eq: "coder.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 600, aspectRatio: 1.5)
      }
    }
    otakuImg: file(relativePath: { eq: "otaku.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 600, aspectRatio: 1.5)
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Box display="grid" mdxType="Box">
  <Box gridArea="1/1" position="relative" placeItems="center" mdxType="Box"> 
    <GatsbyImage image={props.data.aboutPoster.childImageSharp.gatsbyImageData} alt="bg" style={{
          height: '60vh'
        }} mdxType="GatsbyImage" />
  </Box>
  <Container maxW="container.xl" pos="relative" color="white" display="grid" gridArea="1/1" position="relative" placeItems="center" mdxType="Container">
    <Heading my={4} mdxType="Heading">我会带你到月球去！</Heading>
    <HStack width="100%" justify="center" pos="absolute" left="0" bottom={-8} spacing={4} mdxType="HStack">
      <IconButton as={Link} href="https://twitter.com/yukinamij" h={16} minW={16} aria-label="comment" icon={<Text className="iconfont icon-twitter" fontSize="2xl" mdxType="Text" />} borderRadius="full" textDecoration="none !important" mdxType="IconButton" />
      <IconButton as={Link} href="https://weibo.com/yukinami" h={16} minW={16} aria-label="share" icon={<Text className="iconfont icon-weibo" fontSize="2xl" mdxType="Text" />} borderRadius="full" textDecoration="none !important" mdxType="IconButton" />
    </HStack>
  </Container>
    </Box>
    <Container maxW="container.xl" mdxType="Container">
      <NumHeading num="01" title="关于我" mdxType="NumHeading" />
      <p>{`我是雪落，熟悉Web领域相关系统开发的Coder；ACG爱好者，热爱动画、游戏、影视作品；喜欢用影像，记录时光、保存记忆。`}</p>
      <p>{`爱折腾效率工具，日常使用agenda来做的子弹笔记。阅读流使用inoread收集咨询、cubox的做稍后读、readwise整理和回顾。喜欢折腾NAS、软路由， 目前有台`}<inlineCode parentName="p">{`all in boom`}</inlineCode>{`，实现旁路由、NAS、媒体服务器、蓝光播放器。`}</p>
      <p>{`平常逛的最多的是小破站，喜欢关注外居家还有户外这两个看矛盾的板块。其实也不是很矛盾，现在的精致露营本质上就是把家搬到户外的，重点也是享受生活，而且自从追了摇曳露营后，更是对户外的爱一发不可收拾。`}</p>
      <Tabs my={16} sx={{
        '& > div': {
          border: 0
        }
      }} mdxType="Tabs">
  <TabList flexDirection={['column', 'row']} mdxType="TabList">
    <CustomTab expand={true} mdxType="CustomTab">
      <IntroCard title="Coder" description="码农一名，热爱折腾、热爱技术" img={props.data.coderImg.childImageSharp.gatsbyImageData} mdxType="IntroCard" />
    </CustomTab>
    <CustomTab expand={true} mdxType="CustomTab">
      <IntroCard title="Otaku" description="二次元御宅，喜欢动画、游戏" img={props.data.otakuImg.childImageSharp.gatsbyImageData} mdxType="IntroCard" />
    </CustomTab>
  </TabList>
  <TabPanels mdxType="TabPanels">
    <TabPanel mdxType="TabPanel">
      <NumHeading num="02" title="Coder" mdxType="NumHeading" />
      <Coder mdxType="Coder" />
    </TabPanel>
    <TabPanel mdxType="TabPanel">
      <NumHeading num="03" title="Otaku" mdxType="NumHeading" />
      <Otaku mdxType="Otaku" />
    </TabPanel>
  </TabPanels>
      </Tabs>
      <NumHeading num="04" title="关于本站" mdxType="NumHeading" />
      <p>{`我用过好多工具来建站，像Hexo、Jekyll这种采用基于模板引擎的静态站点生成框架，也有用Farfox、Bitcron这样的博客平台。
他们在扩展性都不是特别理想，都无法使用上现代的JS框架生态，或者实现起来很麻烦。直到我发现了Gatsby，他可以使用React技术栈来实现SSG，并且可以通过GraphQL获取数据。使用React技术栈，意味着可以使用整个React的生态。MDX、Emotion、Styled System、Framer-Motion，任何你能想到酷炫的技术都能用上，这真是太酷啦。`}</p>
      <p>{`于是就有了本站，我希望它成为我的一个内容发布平台，所以它包含四个频道，分别用于技术内容、二次元相关、日常记录、摄影，各自有不同的内容呈现方式。内容除了通过频道分页展示了，还有传统的归档页面，方便去查看所有的文章。它并没有采用传统的标签云方式来展现细化的内容类别，取而代之的是在搜索功能中集成了标签的搜索，同时可以查看所有的相关的标签的内容。然后它还有一些相对现代的工具：夜间模式, 字体大小调整, RSS订阅。`}</p>
      <p>{`另外我并没有采用Disqus这样的评论服务，而是使用Staticman这种评论静态生成的方式来实现的。你评论的内容并不是实施展示在评论区，而是会用过Staticman服务生成一个静态文件，并自动提交PR到的博客仓库，最终重新构建完后才会得到展示。所以它更像是一个留言系统。`}</p>
      <p>{`该站点的另外一个重要作用就是我的前端相关技术的试验田。因为平时前端的代码的写的也不多，我希望通过该站点保持和前端社区的联系。`}</p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      