import { Box, Heading, useColorModeValue, useTheme } from '@chakra-ui/react';
import React from "react";

export interface NumHeadingProps {
  num: string;
  title: string;
}

const NumHeading = ({ num, title }: NumHeadingProps) => {
  const theme = useTheme();
  return (
    <Box pos="relative" my={8}>
      <Heading
        as="h1"
        fontSize="9rem"
        fontWeight="normal"
        lineHeight="0.8"
        color={useColorModeValue('blackAlpha.200', 'whiteAlpha.700')}
      >
        {num}
      </Heading>
      <Heading
        pos="absolute"
        bottom="0"
        fontWeight="normal"
        fontSize="4xl"
        left={`calc(${theme.fontSizes['4xl']} * 3)`}
        color={useColorModeValue('blackAlpha.500', 'whiteAlpha.800')}
      >
        {title}
      </Heading>
    </Box>
  );
};

export default NumHeading;
