import React from 'react';
import {
  GatsbyImage,
  StaticImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image';
import { Img, Box, Heading } from '@chakra-ui/react';
import { MotionBox } from './MotionComps';

export interface IntroCardProps {
  title?: string;
  description: string;
  isActive: boolean;
  img: string | IGatsbyImageData;
  onMouseEnter: () => void;
}

const hoverVariants = {
  active: { scale: 1.1 },
  unactive: { scale: 1 },
};

const IntroCard = ({
  title,
  description,
  isActive,
  img,
  onMouseEnter,
}: IntroCardProps) => {
  let image: React.ReactNode;
  if (typeof img === 'string') {
    image = (
      <Img
        as={StaticImage}
        src={img as string}
        alt="intro"
        borderRadius="md"
        boxShadow="primary"
        aspectRatio={4/3}
      />
    );
  } else {
    image = (
      <Img
        as={GatsbyImage}
        image={img as IGatsbyImageData}
        alt="intro"
        borderRadius="md"
        boxShadow="primary"
      />
    );
  }

  return (
    <MotionBox
      flex="1"
      pos="relative"
      mx={[0, 8]}
      my={[4, 0]}
      animate={isActive ? 'active' : 'unactive'}
      variants={hoverVariants}
      onMouseEnter={onMouseEnter}
    >
      {image}
      <Box
        layerStyle="center"
        color="white"
        width="100%"
        backgroundColor="hsla(0, 0%, 50%, .2)"
        p={4}
      >
        <Heading mb={4}>{title}</Heading>
        {description}
      </Box>
    </MotionBox>
  );
};

export default IntroCard;
